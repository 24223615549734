import React from 'react';

function Home() {
  return (
    <div>
      <h1>Home</h1>
      <p>Start your app here.</p>
    </div>
  );
}

export default Home;
